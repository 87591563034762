const Constants = {
    MEMBERSHIP_ID: "MembershipUserId",
	DATE_FORMATE_DD_MM_YYYY: "DD/MM/YYYY",
	ORDERSUPPORT_NOTIFICATION_BUBBLE: 'OrderSupportBubble',
	snoozeTime: "snoozeTime",
	
	Session:{
		USER_OBJECT: "user_obj",
		ADMIN_OBJECT: "admin_obj",
		PARENT_OBJECT: "Parent_Obj",
		JWT_TOKEN: "jwt_token",
        AUTHENTICATION_TICKET: "AuthenticationTicket",
        ADDON_GROUP_ITEMS: "AddonGroupItems",
        ADDON_GROUP_IDs:"TableRecordIdsCsv",
		ALL_PERMISSION: "all_permission",
		ENTERPRISE_ID: "EnterpriseId",
		ENTERPRISE_NAME: "EnterpriseName",
		ENTERPRISE_TYPE_ID: "EnterpriseTypeId",
		IMPERSONATORID: "ImpersonatorID",
		PARENTIMPERSONATORID: "ParentImpersonatorID",
		IS_MENU_MODIFIED: "IsMenuModified",
		SESSION_START_AT: "SessionStartAt",
		FORGOT_PASSWORD_OBJECT: "ForgotPasswordInfo",
		NEW_ORDER_LIST: "NewOrderList",
		SELECTED_ORDER_STATUS: "SelectedOrderStatus",
		SELECTED_REPORT_ORDER_STATUS: "SelectedReportOrderStatus",
		COUNTRY_CONFIGURATION: "CountryConfiguration",
		SELECTED_COMPLAINTS_STATUS: "SelectedComplaintStatus",
		SELECTED_PAST_COMPLAINTS_STATUS: "SelectedPastComplaintStatus",
		SELECTED_ORDER_QUERY: "SelectedOrderQuery",
		SELECTED_ADMIN_ORDER_QUERY: "SelectedAdminOrderQuery",
		SITE_URL:"SiteUrl",
		S3FILEVIEWMODE: "ViewMode",
		S3FILESSORTBY: "SortBy",
		S3SHOWHIDDENFILE: "ShowHiddenFiles"
		
    },
    MenuAddonGroupTopping: "0",
    MenuAddonGroupExtra: "1",
    Permission: {
		
		//#region ENTERPRISE_SETTING Constants

		ENTERPRISE_SETTING_READ : "ENTERPRISE_SETTING_READ",
		ENTERPRISE_SETTING_UPDATE : "ENTERPRISE_SETTING_UPDATE",

	   //#endregion

	   //#region RESELLER_MANAGER Constants

	    RESSELER_KEY_ACCOUNT_CREATE : "RESSELER_KEY_ACCOUNT_CREATE",
        RESSELER_KEY_ACCOUNT_READ :   "RESSELER_KEY_ACCOUNT_READ",
        RESSELER_KEY_ACCOUNT_UPDATE : "RESSELER_KEY_ACCOUNT_UPDATE",
        RESSELER_KEY_ACCOUNT_DELETE : "RESSELER_KEY_ACCOUNT_DELETE",
        RESSELER_KEY_ACCOUNT_SUSPEND : "RESSELER_KEY_ACCOUNT_SUSPEND",
        RESSELER_KEY_ACCOUNT_ACTIVATE : "RESSELER_KEY_ACCOUNT_ACTIVATE",
        RESSELER_KEY_ACCOUNT_IMPERSONATE : "RESSELER_KEY_ACCOUNT_IMPERSONATE",
		
		//#endregion

		//#region SYSTEM_OPERATOR Constants

		 SYSTEM_OPERATOR_CREATE : "SYSTEM_OPERATOR_CREATE",
		 SYSTEM_OPERATOR_READ : "SYSTEM_OPERATOR_READ",
		 SYSTEM_OPERATOR_UPDATE : "SYSTEM_OPERATOR_UPDATE",
		 SYSTEM_OPERATOR_DELETE : "SYSTEM_OPERATOR_DELETE",
		 SYSTEM_OPERATOR_SUSPEND : "SYSTEM_OPERATOR_SUSPEND",
		 SYSTEM_OPERATOR_ACTIVATE : "SYSTEM_OPERATOR_ACTIVATE",
		 SYSTEM_OPERATOR_IMPERSONATE : "SYSTEM_OPERATOR_IMPERSONATE",

		//#endregion

		//#region Marketing Admin	Constants

		MARKETING_ADMIN_ADMIN : "MARKETING_ADMIN_ADMIN",

		//#endregion

		//#region Foortal Restaurant Admin
		
		FOORTAL_RESTAURANT_ADMIN_ADMIN : "FOORTAL_RESTAURANT_ADMIN_ADMIN",
		
		//#endregion

		//#region Foortal Restaurant User

		FOORTAL_RESTAURANT_USER_ADMIN : "FOORTAL_RESTAURANT_USER_ADMIN",
		
		//#endregion

		//#region Foortal Support Admin

		 FOORTAL_SUPPORT_ADMIN_ADMIN : "FOORTAL_SUPPORT_ADMIN_ADMIN",

		//#endregion

		//#region Foortal Support User

		 FOORTAL_SUPPORT_USER_ADMIN : "FOORTAL_SUPPORT_USER_ADMIN",

		//#endregion



		//#region AGENT constants

		 AGENT_CREATE : "AGENT_CREATE",
		 AGENT_READ : "AGENT_READ",
		 AGENT_UPDATE : "AGENT_UPDATE",
		 AGENT_DELETE : "AGENT_DELETE",
		 AGENT_SUSPEND : "AGENT_SUSPEND",
		 AGENT_ACTIVATE : "AGENT_ACTIVATE",
		 AGENT_IMPERSONATE : "AGENT_IMPERSONATE",

		//#endregion

		//#region ENTERPRISE_ADMIN

		 ENTERPRISE_ADMIN_CREATE : "ENTERPRISE_ADMIN_CREATE",
		 ENTERPRISE_ADMIN_READ : "ENTERPRISE_ADMIN_READ",
		 ENTERPRISE_ADMIN_UPDATE : "ENTERPRISE_ADMIN_UPDATE",
		 ENTERPRISE_ADMIN_DELETE : "ENTERPRISE_ADMIN_DELETE",
		 ENTERPRISE_ADMIN_SUSPEND : "ENTERPRISE_ADMIN_SUSPEND",
		 ENTERPRISE_ADMIN_ACTIVATE : "ENTERPRISE_ADMIN_ACTIVATE",
		 ENTERPRISE_ADMIN_IMPERSONATE : "ENTERPRISE_ADMIN_IMPERSONATE",
		 ENTERPRISE_ADMIN_APPROVED : "ENTERPRISE_ADMIN_APPROVED",

		//#endregion

		//#region ENTERPRISE_MANAGER

		 ENTERPRISE_MANAGER_CREATE : "ENTERPRISE_MANAGER_CREATE",
		 ENTERPRISE_MANAGER_READ : "ENTERPRISE_MANAGER_READ",
		 ENTERPRISE_MANAGER_UPDATE : "ENTERPRISE_MANAGER_UPDATE",
		 ENTERPRISE_MANAGER_DELETE : "ENTERPRISE_MANAGER_DELETE",
		 ENTERPRISE_MANAGER_SUSPEND : "ENTERPRISE_MANAGER_SUSPEND",
		 ENTERPRISE_MANAGER_ACTIVATE : "ENTERPRISE_MANAGER_ACTIVATE",
		 ENTERPRISE_MANAGER_IMPERSONATE : "ENTERPRISE_MANAGER_IMPERSONATE",
		 ENTERPRISE_MANAGER_APPROVED : "ENTERPRISE_MANAGER_APPROVED",

		//#endregion

		//#region   ENTERPRISE_USER

		 ENTERPRISE_USER_CREATE : "ENTERPRISE_USER_CREATE",
		 ENTERPRISE_USER_READ : "ENTERPRISE_USER_READ",
		 ENTERPRISE_USER_UPDATE : "ENTERPRISE_USER_UPDATE",
		 ENTERPRISE_USER_DELETE : "ENTERPRISE_USER_DELETE",
		 ENTERPRISE_USER_SUSPEND : "ENTERPRISE_USER_SUSPEND",
		 ENTERPRISE_USER_ACTIVATE : "ENTERPRISE_USER_ACTIVATE",
		 ENTERPRISE_USER_IMPERSONATE : "ENTERPRISE_USER_IMPERSONATE",
		 ENTERPRISE_USER_APPROVED : "ENTERPRISE_USER_APPROVED",

		//#endregion

		//#region  CONSUMER

		 CONSUMER_READ : "ENTERPRISE_USER_APPROVED",
		 CONSUMER_UPDATE : "CONSUMER_UPDATE",
		 CONSUMER_DELETE : "CONSUMER_DELETE",
		 CONSUMER_SUSPEND : "CONSUMER_SUSPEND",
		 CONSUMER_ACTIVATE : "CONSUMER_ACTIVATE",
		 CONSUMER_IMPERSONATE : " CONSUMER_IMPERSONATE",
		 CONSUMER_APPROVED : "CONSUMER_APPROVED",

		//#endregion

		//#region  ADVERTISER

		 ADVERTISER_CREATE : "ADVERTISER_CREATE",
		 ADVERTISER_READ : "ADVERTISER_READ",
		 ADVERTISER_UPDATE : "ADVERTISER_UPDATE",
		 ADVERTISER_DELETE : "ADVERTISER_DELETE",
		 ADVERTISER_SUSPEND : "ADVERTISER_DELETE",
		 ADVERTISER_ACTIVATE : "ADVERTISER_ACTIVATE",
		 ADVERTISER_IMPERSONATE : "ADVERTISER_IMPERSONATE",
		 ADVERTISER_APPROVED : "ADVERTISER_APPROVED",

		//#endregion

		//#region  FINANCE

		 FINANCE_CREATE : "FINANCE_CREATE",
		 FINANCE_READ : "FINANCE_READ",
		 FINANCE_UPDATE : "FINANCE_UPDATE",
		 FINANCE_DELETE : "FINANCE_DELETE",
		 FINANCE_SUSPEND : "FINANCE_SUSPEND",
		 FINANCE_ACTIVATE : "FINANCE_ACTIVATE",
		 FINANCE_IMPERSONATE : "FINANCE_IMPERSONATE",
		 FINANCE_APPROVED : "FINANCE_APPROVED",

		//#endregion

		//#region  AGENT_COMMISSION

		 AGENT_COMMISSION_CREATE : "AGENT_COMMISSION_CREATE",
		 AGENT_COMMISSION_READ : "AGENT_COMMISSION_READ",
		 AGENT_COMMISSION_UPDATE : "AGENT_COMMISSION_UPDATE",
		 AGENT_COMMISSION_DELETE : "AGENT_COMMISSION_DELETE",

		//#endregion

		//#region    RESTAURANT_COMMISSION

		 RESTAURANT_COMMISSION_CREATE : "RESTAURANT_COMMISSION_CREATE",
		 RESTAURANT_COMMISSION_READ : "RESTAURANT_COMMISSION_READ",
		 RESTAURANT_COMMISSION_UPDATE : "RESTAURANT_COMMISSION_UPDATE",
		 RESTAURANT_COMMISSION_DELETE : "RESTAURANT_COMMISSION_DELETE",

		//#endregion

		//#region  ENTERPRISE_RESTAURANT

		 ENTERPRISE_RESTAURANT_CREATE : "ENTERPRISE_RESTAURANT_CREATE",
		 ENTERPRISE_RESTAURANT_READ : "ENTERPRISE_RESTAURANT_READ",
		 ENTERPRISE_RESTAURANT_UPDATE : "ENTERPRISE_RESTAURANT_UPDATE",
		 ENTERPRISE_RESTAURANT_DELETE : "ENTERPRISE_RESTAURANT_DELETE",
		 ENTERPRISE_RESTAURANT_SUSPEND : "ENTERPRISE_RESTAURANT_SUSPEND",
		 ENTERPRISE_RESTAURANT_ACTIVATE : "ENTERPRISE_RESTAURANT_ACTIVATE",
		 ENTERPRISE_RESTAURANT_IMPERSONATE : "ENTERPRISE_RESTAURANT_IMPERSONATE",
		 ENTERPRISE_RESTAURANT_APPROVED : "ENTERPRISE_RESTAURANT_APPROVED",
		 ENTERPRISE_RESTAURANT_REGISTER : "ENTERPRISE_RESTAURANT_REGISTER",
		 ENTERPRISE_CHURNED_UPDATE : "ENTERPRISE_CHURNED_UPDATE",
		//#endregion

		//#region  ENTERPRISE_GROUP

		 ENTERPRISE_GROUP_CREATE : "ENTERPRISE_GROUP_CREATE",
		 ENTERPRISE_GROUP_READ : "ENTERPRISE_GROUP_READ",
		 ENTERPRISE_GROUPUPDATE : "ENTERPRISE_GROUP_UPDATE",
		 ENTERPRISE_GROUP_DELETE : "ENTERPRISE_GROUP_DELETE",
		//#endregion

		//#region   GLOBAL_SETTING

		 GLOBAL_SETTING_CREATE : "GLOBAL_SETTING_CREATE",
		 GLOBAL_SETTING_READ : "GLOBAL_SETTING_READ",
		 GLOBAL_SETTING_UPDATE : "GLOBAL_SETTING_UPDATE",
		 GLOBAL_SETTING_DELETE : "GLOBAL_SETTING_DELETE",

		//#endregion

		//#region REPORTS

		 REPORTS_READ : "REPORTS_READ",

		//#endregion

		//#region  COUNTY_STATE_CITY

		 COUNTY_STATE_CITY_CREATE : "COUNTY_STATE_CITY_CREATE",
		 COUNTY_STATE_CITY_READ : "COUNTY_STATE_CITY_READ",
		 COUNTY_STATE_CITY_UPDATE : "COUNTY_STATE_CITY_UPDATE",
		 COUNTY_STATE_CITY_DELETE : "COUNTY_STATE_CITY_DELETE",
		 COUNTY_STATE_CITY_APPROVED : "COUNTY_STATE_CITY_APPROVED",

		//#endregion

		//#region  COUNTRIES

		 COUNTRIES_CREATE : "COUNTRIES_CREATE",
		 COUNTRIES_READ : "COUNTRIES_READ",
		 COUNTRIES_UPDATE : "COUNTRIES_UPDATE",
		 COUNTRIES_DELETE : "COUNTRIES_DELETE",
		 COUNTRIES_APPROVED : "COUNTRIES_APPROVED",

		//#endregion

		//#region AREA

		 AREA_CREATE : "AREA_CREATE",
		 AREA_READ : "AREA_READ",
		 AREA_UPDATE : "AREA_UPDATE",
		 AREA_DELETE : "AREA_DELETE",

		//#endregion

		//#region AREA MAPPING

		 AREA_MAPPING_CREATE : "AREA_MAPPING_CREATE",
		 AREA_MAPPING_READ : "AREA_MAPPING_READ",
		 AREA_MAPPING_UPDATE : "AREA_MAPPING_UPDATE",
		 AREA_MAPPING_DELETE : "AREA_MAPPING_DELETE",

		//#endregion

		//#region PHOTO

		 PHOTO_CREATE : "PHOTO_CREATE",
		 PHOTO_READ : "PHOTO_READ",
		 PHOTO_UPDATE : "PHOTO_UPDATE",
		 PHOTO_DELETE : "PHOTO_DELETE",
		 PHOTO_SUSPEND : "PHOTO_SUSPEND",
		 PHOTO_ACTIVATE : "PHOTO_ACTIVATE",
		 PHOTO_APPROVED : "PHOTO_APPROVED",
		 PHOTO_UN_APPROVED : "PHOTO_UN_APPROVED",

		//#endregion

		//#region   ASOSIATE_USER

		 ASOSIATE_USER_ENTERPRISE_READ : "ASOSIATE_USER_ENTERPRISE_READ",
		 ASOSIATE_USER_ENTERPRISE_UPDATE : "ASOSIATE_USER_ENTERPRISE_UPDATE",

		//#endregion region

		//#region  RESTAURANT_CATEGORY

		 RESTAURANT_CATEGORY_CREATE : "RESTAURANT_CATEGORY_CREATE",
		 RESTAURANT_CATEGORY_READ : "RESTAURANT_CATEGORY_READ",
		 RESTAURANT_CATEGORY_UPDATE : "RESTAURANT_CATEGORY_UPDATE",
		 RESTAURANT_CATEGORY_DELETE : "RESTAURANT_CATEGORY_DELETE",
		 RESTAURANT_CATEGORY_PUBLISHED : "RESTAURANT_CATEGORY_PUBLISHED",
		 RESTAURANT_CATEGORY_UN_PUBLISHED : "RESTAURANT_CATEGORY_UN_PUBLISHED",

		//#endregion

		//#region RESTAURANT_ITEM

		 RESTAURANT_ITEM_CREATE : "RESTAURANT_ITEM_CREATE",
		 RESTAURANT_ITEM_READ : "RESTAURANT_ITEM_READ",
		 RESTAURANT_ITEM_UPDATE : "RESTAURANT_ITEM_UPDATE",
		 RESTAURANT_ITEM_DELETE : "RESTAURANT_ITEM_DELETE",
		 RESTAURANT_ITEM_PUBLISHED : "RESTAURANT_ITEM_PUBLISHED",
		 RESTAURANT_ITEM_UN_PUBLISHED : "RESTAURANT_ITEM_UN_PUBLISHED",

		//#endregion

		//#region   RESTAURANT_TOPPING_GROUP

		 RESTAURANT_TOPPING_GROUP_CREATE : "RESTAURANT_TOPPING_GROUP_CREATE",
		 RESTAURANT_TOPPING_GROUP_READ : "RESTAURANT_TOPPING_GROUP_READ",
		 RESTAURANT_TOPPING_GROUP_UPDATE : "RESTAURANT_TOPPING_GROUP_UPDATE",
		 RESTAURANT_TOPPING_GROUP_DELETE : "RESTAURANT_TOPPING_GROUP_DELETE",
		 RESTAURANT_TOPPING_GROUP_APPROVED : "RESTAURANT_TOPPING_GROUP_APPROVED",
		 RESTAURANT_TOPPING_GROUP_UN_APPROVED : "RESTAURANT_TOPPING_GROUP_UN_APPROVED",
		

		//#endregion

		//#region RESTAURANT_TOPPING

		 RESTAURANT_TOPPING_CREATE : "RESTAURANT_TOPPING_CREATE",
		 RESTAURANT_TOPPING_READ : "RESTAURANT_TOPPING_READ",
		 RESTAURANT_TOPPING_UPDATE : "RESTAURANT_TOPPING_UPDATE",
		 RESTAURANT_TOPPING_DELETE : "RESTAURANT_TOPPING_DELETE",
		 RESTAURANT_TOPPING_APPROVED : "RESTAURANT_TOPPING_APPROVED",
		 RESTAURANT_TOPPING_UN_APPROVED : "RESTAURANT_TOPPING_UN_APPROVED",

		//#endregion

		//#region  RESTAURANT_DEAL

		 RESTAURANT_DEAL_CREATE : " RESTAURANT_DEAL_CREATE",
		 RESTAURANT_DEAL_READ : "RESTAURANT_DEAL_READ",
		 RESTAURANT_DEAL_UPDATE : "RESTAURANT_DEAL_UPDATE",
		 RESTAURANT_DEAL_DELETE : "RESTAURANT_DEAL_DELETE",
		 RESTAURANT_DEAL_APPROVED : "RESTAURANT_DEAL_APPROVED",
		 RESTAURANT_DEAL_UN_APPROVED : "RESTAURANT_DEAL_UN_APPROVED",

		//#endregion

		//#region  RESTAURANT_PROMOTION

		 RESTAURANT_PROMOTION_CREATE : "RESTAURANT_PROMOTION_CREATE",
		 RESTAURANT_PROMOTION_READ : "RESTAURANT_PROMOTION_READ",
		 RESTAURANT_PROMOTION_UPDATE : "RESTAURANT_PROMOTION_UPDATE",
		 RESTAURANT_PROMOTION_DELETE : "RESTAURANT_PROMOTION_DELETE",
		 RESTAURANT_PROMOTION_APPROVED : "RESTAURANT_PROMOTION_APPROVED",
		 RESTAURANT_PROMOTION_UN_APPROVED : "RESTAURANT_PROMOTION_UN_APPROVED",

		//#endregion

		//#region   RESTAURANT_ORDER

		 RESTAURANT_ORDER_READ : "RESTAURANT_ORDER_READ",
		 RESTAURANT_ORDER_UPDATE : "RESTAURANT_ORDER_UPDATE",
		 RESTAURANT_ORDER_DELETE : "RESTAURANT_ORDER_DELETE",
		 RESTAURANT_ORDER_APPROVED : "RESTAURANT_ORDER_APPROVED",
		 RESTAURANT_ORDER_UN_APPROVED : "RESTAURANT_ORDER_APPROVED",

		//#endregion

		//#region  VOUCHERS

		 VOUCHERS_CREATE : "VOUCHERS_CREATE",
		 VOUCHERS_READ : "VOUCHERS_READ",
		 VOUCHERS_UPDATE : "VOUCHERS_UPDATE",
		 VOUCHERS_DELETE : "VOUCHERS_DELETE",
		 VOUCHERS_SUSPEND : "VOUCHERS_SUSPEND",
		 VOUCHERS_ACTIVATE : "VOUCHERS_ACTIVATE",
		 VOUCHERS_APPROVED : "VOUCHERS_APPROVED",
		 VOUCHERS_UN_APPROVED : "VOUCHERS_UN_APPROVED",

		//#endregion

		//#region CONTACT

		 CONTACT_CREATE : "CONTACT_CREATE",
		 CONTACT_READ : "CONTACT_READ",
		 CONTACT_UPDATE : "CONTACT_UPDATE",
		 CONTACT_DELETE : "CONTACT_DELETE",
		 CONTACT_SHARE : "CONTACT_SHARE",
		 CONTACT_GROUP_CREATE : "CONTACT_GROUP_CREATE",
		 CONTACT_GROUP_READ : "CONTACT_GROUP_READ",
		 CONTACT_GROUP_UPDATE : "CONTACT_GROUP_UPDATE",
		 CONTACT_GROUP_DELETE : "CONTACT_GROUP_DELETE",

		//#endregion

		//#region    USER_GROUP

		 USER_GROUP_CREATE : "USER_GROUP_CREATE",
		 USER_GROUP_READ : "USER_GROUP_READ",
		 USER_GROUP_UPDATE : " USER_GROUP_UPDATE",
		 USER_GROUP_DELETE : "USER_GROUP_DELETE",

		//#endregion

		//#region TOPUP

		 TOPUP_UPDATE : "TOPUP_UPDATE",

		//#endregion

		//#region   PROFILE

		 PROFILE_CREATE : "PROFILE_CREATE",
		 PROFILE_READ : "PROFILE_READ",
		 PROFILE_UPDATE : "PROFILE_UPDATE",
		 PROFILE_DELETE : "PROFILE_DELETE",

		//#endregion

		//#region DELIVERY AREA

		 RESTAURANT_DELIVERY_AREA_READ : "RESTAURANT_DELIVERY_AREA_READ",
		 RESTAURANT_DELIVERY_AREA_CREATE : "RESTAURANT_DELIVERY_AREA_CREATE",
		 RESTAURANT_DELIVERY_AREA_UPDATE : "RESTAURANT_DELIVERY_AREA_UPDATE",
		 RESTAURANT_DELIVERY_AREA_DELETE : "RESTAURANT_DELIVERY_AREA_DELETE",
		 RESTAURANT_DELIVERY_AREA_ADMIN : "RESTAURANT_DELIVERY_AREA_ADMIN",

		//#endregion

		//#region OFFER

		 RESTAURANT_OFFER_READ : "RESTAURANT_OFFER_READ",
		 RESTAURANT_OFFER_CREATE : "RESTAURANT_OFFER_CREATE",
		 RESTAURANT_OFFER_UPDATE : "RESTAURANT_OFFER_UPDATE",
		 RESTAURANT_OFFER_DELETE : "RESTAURANT_OFFER_DELETE",

		//#endregion				  

		//#region ADVERTISEMENT

		 ADVERTISEMENT_CREATE : "ADVERTISEMENT_CREATE",
		 ADVERTISEMENT_READ : "ADVERTISEMENT_READ",
		 ADVERTISEMENT_UPDATE : "ADVERTISEMENT_UPDATE",
		 ADVERTISEMENT_DELETE : "ADVERTISEMENT_DELETE",

		//#endregion 

		//#region Notification Constant
		
		 SMS_CASTER_ADMIN : "SMS_CASTER_ADMIN",
		 EMAIL_CASTER_ADMIN : "EMAIL_CASTER_ADMIN",
		 MOBILE_NOTIFICATION : "MOBILE_NOTIFICATION",
		
		//#endregion

	//#region FAKE ORDER REQUEST TICKETS

		 FAKE_ORDER_REQUEST_TICKET_READ : "FAKE_ORDER_REQUEST_TICKET_READ",
		 FAKE_ORDER_REQUEST_TICKET_UPDATE : "FAKE_ORDER_REQUEST_TICKET_UPDATE",
		 FAKE_ORDER_REQUEST_TICKET_CREATE : "FAKE_ORDER_REQUEST_TICKET_CREATE",

		//#endregion


		//#region IMPORT CONTACTS

		 RESTAURANT_IMPORT_BULK_CONTACTS_ADMIN : "RESTAURANT_IMPORT_BULK_CONTACTS_ADMIN",

        //#endregion

        //#region INVOICES
         INVOICES_READ : "INVOICES_READ",

		//#endregion
		
		  //#region CAMPAIGN
		  CAMPAIGN_READ : "CAMPAIGN_READ",
		  CAMPAIGN_CREATE : "CAMPAIGN_CREATE",
		  CAMPAIGN_UPDATE : "CAMPAIGN_UPDATE",
		  CAMPAIGN_SUSPEND : "CAMPAIGN_SUSPEND",
		  CAMPAIGN_ACTIVATE : "CAMPAIGN_ACTIVATE",

		  //#endregion

    },

    Role : {


        //#region Role Constants

		 SYSTEM_ADMIN : "System_Admin",
		 SYSTEM_OPERATOR : "System_Operator",
		 AGENT : "Agent",
		 ENTERPRISE_ADMIN : "Admin",
		 ENTERPRISE_MANAGER : "Manager",
		 ENTERPRISE_USER : "Staff",
		 CORPORATE_ADMIN : "Corporate_Admin",
		 ANONYMOUS : "Anonymous",
		 CONSUMER : "Consumer",
		 ADVERTISER : "Advertiser",
		 MARKETING_ADMIN : "Marketing_Admin",
		 FOORTAL_RESTAURANT_ADMIN : "Foortal_Restaurant_Admin",
		 FOORTAL_RESTAURANT_USER : "Foortal_Restaurant_User",
		 FOORTAL_SUPPORT_ADMIN : "Foortal_Support_Admin",
		 FOORTAL_SUPPORT_USER : "Foortal_Support_User",

		//#endregion

        //#region Role Constants IDs
        
		 SYSTEM_ADMIN_ID : 1,
		 SYSTEM_OPERATOR_ID : 2,
		 RESELLER_ADMIN_ID  : 3,
		 ENTERPRISE_ADMIN_ID : 4,
		 ENTERPRISE_MANAGER_ID : 5,
		 STAFF_ID : 6,
		 CORPORATE_ADMIN_ID : 7,
		 ANONYMOUS_ID : 8,
		 CONSUMER_ID : 0,
		 ADVERTISER_ID : 9,
		 FINANCE_ID : 10,
		 MARKETING_ADMIN_ID : 11,
		 FOORTAL_RESTAURANT_ADMIN_ID : 12,
		 FOORTAL_RESTAURANT_USER_ID : 13,
		 FOORTAL_SUPPORT_ADMIN_ID : 14,
		 FOORTAL_SUPPORT_USER_ID : 15,
  		 RESELLER_MODERATOR_ID : 16,
         RESELLER_KEY_ACCOUNT_MANAGER_ID : 17,
         RESELLER_KEY_ACCOUNT_USER_ID : 18,

		//#endregion

    },

	//Setting Overview Constant

	SetingsOptions: [

		{ name: 'General Settings', url: '/settings/general', icon:'fa fa-cog icons'},
		{ name: 'Addresses', url: '/settings/addresses', icon:'fa fa-home icons'},
		{ name: 'Media', url: '/settings/media', icon:'fa fa-picture-o icons'},
		{ name: 'Working Hours', url: '/settings/working-hours', icon:'fa fa-history icons'},
		{ name: 'Delivery Hours', url: '/settings/delivery-hours', icon:'fa fa-motorcycle icons'},
		{ name: 'Delivery Zones', url: '/settings/deliveryzones', icon:'fa fa-map-marker icons'},
	]

}

export default Constants;